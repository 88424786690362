import React from 'react'
import { graphql } from 'gatsby'
import { Seo } from '../components/base'
import { ExpansionOfferForm } from '../components/forms/ExpansionOfferForm'
import { SinglePage } from '../templates/SinglePage'

const ExpansionOfferPage = ({ data, pageContext }) => {
  const { title, seo, image, imageMobile, expansionsForm } =
    data.expansionOfferPage.nodes[0]
  const languages = { pageContext }
  return (
    <SinglePage
      title={title}
      heroImage={image}
      heroImageMobile={imageMobile}
      heroTitle
      translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <section className="md:px-10 lg:px-0 max-w-6xl mx-auto container">
        <ExpansionOfferForm formData={expansionsForm} />
      </section>
    </SinglePage>
  )
}

export default ExpansionOfferPage

export const pageQuery = graphql`
  query ($language: String!) {
    expansionOfferPage: allSanityExpansionOfferPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        title
        seo {
          ...SeoQuery
        }
        image {
          ...ImageObjectQuery
        }
        imageMobile {
          ...ImageQuery
        }
        expansionsForm {
          city {
            name
            placeholder
            required
            type
            initialValue
          }
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          faxNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          firstName {
            name
            placeholder
            required
            type
            initialValue
          }
          lastName {
            name
            placeholder
            required
            type
            initialValue
          }
          phoneNumber {
            name
            placeholder
            required
            type
            initialValue
          }
          salutation {
            name
            options
            placeholder
            required
            initialValue
          }
          street {
            name
            placeholder
            required
            type
            initialValue
          }
          zipcode {
            name
            placeholder
            required
            type
            initialValue
          }
          locationStreet {
            name
            placeholder
            required
            type
            initialValue
          }
          locationCity {
            name
            placeholder
            required
            type
            initialValue
          }
          locationZipcode {
            name
            placeholder
            required
            type
            initialValue
          }
          size {
            name
            placeholder
            required
            type
            initialValue
          }
          parking {
            name
            placeholder
            required
            type
            initialValue
          }
          other {
            name
            placeholder
            required
            type
            initialValue
          }
          attractions {
            name
            placeholder
            required
            type
            initialValue
          }
          uploadFields {
            hint
            required
            title
            name
            fileTypes
          }
        }
      }
    }
  }
`
